import * as React from "react";
import Dialog from "@mui/material/Dialog";

import {
 
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const Close = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(0.5),
  top: theme.spacing(0.5),
  color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
  padding: "1.5rem",
}));

const InnerContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  gap: "1.5rem",
}));

const ContainerOne = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  borderRight: "1px solid #E0E0E0",
  padding: "0rem 1.5rem 0rem 0rem",
  gap: "0.25rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    gap: "0.75rem",
  },
}));

const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.75rem",
  color: "#00000099",
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: "500",
  lineHeight: "1.251rem",
  color: "#000000",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const ImageDiv = styled("img")(({ theme }) => ({
  width: "100%",
  objectFit: "contain",
  margin: "1rem 0",
}));

const BugInfoModal = ({
  open,
  onClose,
  bugdata,
}: {
  open: any;
  onClose: any;
  bugdata: any;
}) => {



  const date = new Date(bugdata?.createdOn);

  return (
    <div>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Bug Information
          <Close aria-label="close" onClick={onClose}>
            <CloseIcon />
          </Close>
        </DialogTitle>
        <Container>
          <InnerContainer>
            <ContainerOne>
              <HeadText>Created On</HeadText>
              <SubText>{date.toLocaleDateString()}</SubText>
            </ContainerOne>

            <ContainerOne>
              <HeadText>Reported by</HeadText>
              <SubText>
                {bugdata
                  ? bugdata.firstName + " " + bugdata.lastName
                  : "No Name"}
              </SubText>
            </ContainerOne>

            <ContainerOne>
              <HeadText>User ID</HeadText>
              <SubText>{bugdata ? bugdata.userId : "No id"}</SubText>
            </ContainerOne>

            <ContainerOne>
              <HeadText>Mobile Number</HeadText>
              <SubText>{bugdata ? bugdata.phonenumber : "No number"}</SubText>
            </ContainerOne>

            <ImageContainer>
              <img
                src={
                  bugdata?.operatingSystem?.includes("Windows")
                    ? "/images/icons/logo/Windows 11.svg"
                    : bugdata?.operatingSystem?.includes("iOS") &&
                      bugdata?.operatingSystem?.includes("Mac")
                    ? "/images/icons/logo/Apple (1).svg"
                    : bugdata?.operatingSystem?.includes("iOS")
                    ? "/images/icons/logo/Apple.svg"
                    : bugdata?.operatingSystem?.includes("Linux")
                    ? "/images/icons/logo/Linux.svg"
                    : "/images/icons/logo/Windows 11.svg"
                }
                width={40}
                height={40}
                alt=""
              />
              <img
                src={
                  bugdata?.browser?.includes("Chrome")
                    ? "/images/icons/logo/🦆 icon _chrome_.svg"
                    : bugdata?.browser?.includes("Duck")
                    ? "/images/icons/logo/Duck Duck Go.svg"
                    : bugdata?.browser?.includes("Brave")
                    ? "/images/icons/logo/Brave.svg"
                    : bugdata?.browser?.includes("Edge")
                    ? "/images/icons/logo/Edge.svg"
                    : bugdata?.browser?.includes("Firefox")
                    ? "/images/icons/logo/Firefox.svg"
                    : bugdata?.browser?.includes("Opera")
                    ? "/images/icons/logo/Opera.svg"
                    : bugdata?.browser?.includes("Safari")
                    ? "/images/icons/logo/Safari.svg"
                    : bugdata?.browser?.includes("Samsung")
                    ? "/images/icons/logo/Samsung Browser.svg"
                    : bugdata?.browser?.includes("Internet Explorer")
                    ? "/images/icons/logo/Internet Explorer.svg"
                    : "/images/icons/logo/🦆 icon _chrome_.svg"
                }
                width={30}
                height={30}
                alt=""
              />
            </ImageContainer>
          </InnerContainer>

          <ImageDiv
            src={bugdata? bugdata.image_url : "/images/bug.png"}
            alt="bug image"
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              color: "#000000",
              padding: "0rem 0rem 0.5rem 0rem",
            }}
          >
            Description
          </Typography>
          <TextField
            multiline
            rows={4}
            fullWidth
            disabled
            id="fullWidth"
            value={bugdata ? bugdata.description : "No description"}
          />
        </Container>
      </Dialog>
    </div>
  );
};

export default BugInfoModal;
