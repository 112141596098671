import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton, Typography, styled } from "@mui/material";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
	padding: "1.5rem",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "1.5rem",
}));

const ContainerOne = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	borderRight: "1px solid #E0E0E0",
	padding: "0rem 1.5rem 0rem 0rem",
	gap: "0.25rem",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "row",
		gap: "0.75rem",
	},
}));

const ImageContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.75rem",
	color: "#00000099",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: "500",
	lineHeight: "1.251rem",
	color: "#000000",
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const ImageDiv = styled("img")(({ theme }) => ({
	width: "100%",
	objectFit: "contain",
	margin: "1rem 0",
}));

const ReasonModal = ({ open, onClose, bugdata }: { open: any; onClose: any; bugdata: any }) => {
	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						color: "primary.main",
					}}
				>
					Transaction Purpose Details
					<Close
						aria-label="close"
						onClick={onClose}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<Container>
					<Typography
						variant="h6"
						sx={{
							color: "text.primary",
							fontWeight: "400",
							marginBottom: "1rem",
						}}
					>
						{bugdata}
					</Typography>
				</Container>
			</Dialog>
		</div>
	);
};

export default ReasonModal;
