/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Add, ArrowDropDown, CancelOutlined, NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Chip, Divider, Link, Typography, useTheme } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "untouched" || id === "lead_generated"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "work_in_progress" || id === "prospect_created"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: id === "pending_qc" || id === "meeting_scheduled"
				? {
						backgroundColor: "rgba(237, 108, 2, 0.5)",
					}
				: id === "published" || id === "onboarded"
					? {
							backgroundColor: "rgba(76, 175, 80, 0.5)",
						}
					: {
							backgroundColor: "rgba(255, 255, 255, 0.002)",
						},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const BusinessDevelopmentDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a constant for the menu open

  */

	const theme = useTheme();

	/*

  & Next, let's define the data for the menu items

  */

	const menuItems = [
		{
			id: 1,
			label: "Untouched",
			value: "untouched",
		},
		{
			id: 2,
			label: "Work in progress",
			value: "work_in_progress",
		},
		{
			id: 3,
			label: "Pending QC",
			value: "pending_qc",
		},
		{
			id: 4,
			label: "Published",
			value: "published",
		},
	];

	/*

  & Next, let's define the data for the menu items for assigned

  */

	const assigned = [
		{
			id: 1,
			label: "Praveen",
			value: "praveen",
		},
		{
			id: 2,
			label: "Gunadeep",
			value: "gunadeep",
		},
		{
			id: 3,
			label: "Nidal Abrar",
			value: "nidal_abrar",
		},
		{
			id: 4,
			label: "Username",
			value: "username",
		},
	];

	const tasks = [
		{
			id: 1,
			label: "Lead Generated",
			value: "lead_generated",
		},
		{
			id: 2,
			label: "Prospect Created",
			value: "prospect_created",
		},
		{
			id: 3,
			label: "Meeting Scheduled",
			value: "meeting_scheduled",
		},
		{
			id: 4,
			label: "Meeting Completed",
			value: "meeting_completed",
		},
	];

	/*



  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "prospectName",
			headerName: "Prospect Name",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href="/"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "prospectPhoneNumber",
			headerName: "Prospect Phone Number",
			width: 200,
		},
		{
			field: "assigned",
			headerName: "Assigned",
			width: 200,
			editable: true,
			// type: "singleSelect",
			valueOptions: assigned,
			valueFormatter: (params: any) => {
				return params.value === "praveen"
					? "Praveen"
					: params.value === "gunadeep"
						? "Gunadeep"
						: params.value === "nidal_abrar"
							? "Nidal Abrar"
							: params.value === "username"
								? "Username"
								: "Assign to";
			},
			renderCell: (cellValues: any) => {
				return (
					<>
						<Chip
							label={cellValues.formattedValue}
							variant="outlined"
							onDelete={() => {}}
							deleteIcon={cellValues.formattedValue === "Assign to" ? <Add /> : <CancelOutlined />}
							color="primary"
						/>
					</>
				);
			},
		},
		{
			field: "task",
			headerName: "Task",
			width: 200,
			editable: true,
			// type: "singleSelect",
			valueOptions: tasks,
			valueFormatter: (params: any) => {
				return params.value === "lead_generated"
					? "Lead Generated"
					: params.value === "prospect_created"
						? "Prospect Created"
						: params.value === "meeting_scheduled"
							? "Meeting Scheduled"
							: params.value === "onboarded"
								? "Onboarded"
								: "";
			},
			renderCell: (cellValues: any) => {
				return (
					<ChipData
						id={cellValues.value}
						label={cellValues.formattedValue}
						variant="filled"
						onDelete={() => {}}
						deleteIcon={<ArrowDropDown />}
					/>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			width: 200,
			editable: true,
			// type: "singleSelect",
			valueOptions: menuItems,
			valueFormatter: (params: any) => {
				return params.value === "untouched"
					? "Untouched"
					: params.value === "work_in_progress"
						? "Work in progress"
						: params.value === "pending_qc"
							? "Pending QC"
							: params.value === "published"
								? "Published"
								: "";
			},
			renderCell: (cellValues: any) => {
				return (
					<ChipData
						id={cellValues.value}
						label={cellValues.formattedValue}
						variant="filled"
						onDelete={() => {}}
						deleteIcon={<ArrowDropDown />}
					/>
				);
			},
		},
		{
			field: "dueDate",
			headerName: "Due Date",
			width: 200,
			editable: true,
		},
		{
			field: "action",
			headerName: "Action",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ImgTag
							src={
								theme.palette.mode === "dark"
									? "/images/icons/social/call_blue.svg"
									: "/images/icons/social/call_blue.svg"
							}
							alt="Share icon"
							loading="lazy"
							referrerPolicy="no-referrer"
						/>
						<ImgTag
							src={
								theme.palette.mode === "dark"
									? "/images/icons/social/whatsapp_green.svg"
									: "/images/icons/social/whatsapp_green.svg"
							}
							alt="Share icon"
							loading="lazy"
							referrerPolicy="no-referrer"
						/>
						<ImgTag
							src={theme.palette.mode === "dark" ? "/images/icons/social/edit.svg" : "/images/icons/social/edit.svg"}
							alt="Share icon"
							loading="lazy"
							referrerPolicy="no-referrer"
						/>
					</ActionActions>
				);
			},
		},
	];

	/*

  & Next, let's define the row data for the table

  */

	const rows = [
		{
			id: 1,
			prospectName: "Heavie Eden",
			prospectPhoneNumber: "0987654321",
			task: "lead_generated",
			status: "work_in_progress",
			assigned: "User 1",
			dueDate: "14th April 2023",
			action: "Edit",
		},
		{
			id: 2,
			prospectName: "Prisha Orchids",
			prospectPhoneNumber: "0987654321",
			task: "prospect_created",
			status: "untouched",
			assigned: "User 2",
			dueDate: "14th April 2023",
			action: "Edit",
		},
		{
			id: 3,
			prospectName: "Akruthi Green Woods",
			prospectPhoneNumber: "0987654321",
			task: "meeting_scheduled",
			status: "published",
			assigned: "User 3",
			dueDate: "14th April 2023",
			action: "Edit",
		},

		{
			id: 4,
			prospectName: "Ittina mahavir",
			prospectPhoneNumber: "0987654321",
			task: "onboarded",
			status: "work_in_progress",
			assigned: "User 4",
			dueDate: "14th April 2023",
			action: "Edit",
		},
		{
			id: 5,
			prospectName: "Nambiar Bellezea",
			prospectPhoneNumber: "0987654321",
			task: "lead_generated",
			status: "pending_qc",
			assigned: "User 5",
			dueDate: "14th April 2023",
			action: "Edit",
		},
		{
			id: 6,
			prospectName: "Nambiar Ellegenza",
			prospectPhoneNumber: "0987654321",
			task: "prospect_created",
			status: "work_in_progress",
			assigned: "User 6",
			dueDate: "14th April 2023",
			action: "Edit",
		},
		{
			id: 7,
			prospectName: "Nambiar Ellegenza",
			prospectPhoneNumber: "0987654321",
			task: "meeting_scheduled",
			status: "work_in_progress",
			assigned: "User 6",
			dueDate: "14th April 2023",
			action: "Edit",
		},
		{
			id: 8,
			prospectName: "Nambiar Ellegenza",
			prospectPhoneNumber: "0987654321",
			task: "onboarded",
			status: "work_in_progress",
			assigned: "User 6",
			dueDate: "14th April 2023",
			action: "Edit",
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#2196F3" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#2196F3" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#2196F3" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#2196F3" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	const handleClick = () => {};

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Business Development
		</Typography>,
	];

	return (
		<MainContainer>
			<HeadContainer>
				<HeadText>Business Development</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			<Box sx={{ height: "100%", padding: " 1rem", width: "100%" }}>
				<DataGridPremium
					disableRowSelectionOnClick={false}
					slots={{ toolbar: GridToolbar }}
					rows={rows}
					columns={columns}
					pagination={true}
					pageSizeOptions={[25, 50, 100]}
				/>
			</Box>
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default BusinessDevelopmentDataTable;
