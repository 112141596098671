/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Chip, debounce, Divider, Link, Paper, Typography } from "@mui/material";
import { useRouter } from "next/router";
import ActionModal from "../modal/ActionModal";

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "Business Profile"
		? {
				backgroundColor: "rgba(76, 175, 80, 0.5)",
			}
		: id === "User Profile"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	// height: "calc(100vh - 0rem)",
	// overflowY: "scroll",
}));

const MainInnerContainer = styled("div")(({ theme }) => ({
	height: "100vh",
	width: "100%",
	padding: "1rem",
	overflowY: "scroll",
}));
const SubMainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
}));
const SubContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "1rem",
	padding: "1rem",
	margin: "1rem",
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "0rem 0rem 0.5rem 0rem",
}));

const ActivityDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

    & Next, let's define a local state for the modal

  */

	const [openMoadal, setOpenMoadal] = React.useState(false);

	const [bugdata, setBugData] = React.useState<any>([]);

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.ActivityLogData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.ActivityLogData || []);
	}, [DashboardData]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "activity-log-table/activity-log-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
				}),
			});

			const res = await data.json();
			console.log(res);
			setDashboardData(res.payload.ActivityLogData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "log_id",
			headerName: "Log ID",
			width: 200,
		},
		{
			field: "log_creator_type",
			headerName: "Log Creator Type",
			width: 180,
			renderCell: (cellValues: any) => {
				return (
					<ChipData
						id={cellValues.value}
						label={cellValues.value}
						variant="outlined"
					/>
				);
			},
		},
		{
			field: "log_creator",
			headerName: "Log Creator ",
			width: 200,
		},
		{
			field: "action",
			headerName: "Action",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Link
						onClick={() => {
							setOpenMoadal(true);
							setBugData(cellValues.value);
						}}
						sx={{
							textDecoration: "none",
							cursor: "pointer",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "entity_type",
			headerName: "Entity Type",
			width: 150,
		},
		{
			field: "entity",
			headerName: "Entity",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={
							cellValues.row.entity_type === "property"
								? "https://beegru.com/properties/" + cellValues.row.entity.entity_id?.slug
								: cellValues.row.entity_type === "service"
									? "https://beegru.com/services/" + cellValues.row.entity_id?.slug
									: cellValues.row.entity_type === "business profile"
										? cellValues.row.entity?.entity_id?.business_profile_type === "agent"
											? "https://beegru.com/agents/" + cellValues.row.entity?.entity_id?.slug
											: cellValues.row.entity?.entity_id?.business_profile_type === "developer"
												? "https://beegru.com/developers/" + cellValues.row.entity?.entity_id?.slug
												: cellValues.row.entity?.entity_id?.business_profile_type === "landowner"
													? "https://beegru.com/landowners/" + cellValues.row.entity?.entity_id?.slug
													: cellValues.row.entity?.entity_id?.business_profile_type === "professional"
														? "https://beegru.com/professionals/" + cellValues.row.entity?.entity_id?.slug
														: ""
										: cellValues.row.entity_type === "user"
											? "https://beegru.com/users/" + cellValues.row.entity?.entity_id?._id
											: cellValues.row.entity_type === "post"
												? "https://beegru.com/post/" + cellValues.row.entity.entity_id?._id
												: ""
						}
						target="_blank"
						sx={{
							textDecoration: "none",
							cursor: "pointer",
						}}
					>
						{cellValues.row.entity_type === "property"
							? cellValues.row.entity?.entity_id?.title
							: cellValues.row.entity_type === "service"
								? cellValues.row.entity?.entity_id?.title
								: cellValues.row.entity_type === "business profile"
									? cellValues.row.entity?.entity_id?.title
									: cellValues.row.entity_type === "user"
										? cellValues.row.entity?.entity_id?.first_name + " " + cellValues.row.entity?.entity_id?.last_name
										: cellValues.row.entity_type === "post"
											? cellValues.row.entity?.entity_id?._id
											: ""}
					</Link>
				);
			},
		},
		{
			field: "action_performed_at",
			headerName: "Action Performed At",
			width: 200,
			filterable: false,
		},
	];

	const columnsForProperty = [
		{
			field: "_id",
			headerName: "ID",
			width: 200,
		},
		{
			field: "title",
			headerName: "Title",
			width: 200,
		},
	];
	const columnsForReport = [
		{
			field: "_id",
			headerName: "ID",
			width: 200,
		},
		{
			field: "report_type",
			headerName: "Report Type",
			width: 200,
		},
	];

	const columnsForBugReport = [
		{
			field: "_id",
			headerName: "ID",
			width: 200,
		},
		{
			field: "url",
			headerName: "URL",
			width: 200,
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Activity Log
		</Typography>,
	];

	return (
		<MainContainer>
			<ActionModal
				open={openMoadal}
				onClose={() => setOpenMoadal(false)}
				bugdata={bugdata}
			/>
			<HeadContainer>
				<HeadText>Activity Log</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			<MainInnerContainer>
				{DashboardData.accessLevel &&
				((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("activity_log")) ||
					DashboardData.accessLevel.includes("super_admin")) ? (
					DashboardData.AnalyticsData || !DashboardData.ActivityLogData ? null : (
						<>
							<SubMainContainer>
								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Publish Requested Properties</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.propertyPublishRequestsPendingCount}</HeadText>
										</InnerContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Not Assigned</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.propertyPublishRequestedAssigned}</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.propertyPublishRequestsPending?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													title: item.title,
												};
											})}
											// rows={bugdata}
											columns={columnsForProperty}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "property",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>
								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Untouched Properties</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.propertyUntouchedCount}</HeadText>
										</InnerContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Not Assigned</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.propertyUntouchedCountAssigned}</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.propertyUntouched?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													title: item.title,
												};
											})}
											columns={columnsForProperty}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "property",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>
							</SubMainContainer>
							<SubMainContainer>
								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Publish Requested Services</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.servicePublishRequestsPendingCount}</HeadText>
										</InnerContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Not Assigned</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.servicePublishRequestedAssigned}</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.servicePublishRequestsPending?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													title: item.title,
												};
											})}
											// rows={bugdata}
											columns={columnsForProperty}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "service",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>

								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Untouched Services</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.serviceUntouchedCount}</HeadText>
										</InnerContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Not Assigned</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.serviceUntouchedCountAssigned}</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.serviceUntouched?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													title: item.title,
												};
											})}
											columns={columnsForProperty}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "service",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>
							</SubMainContainer>
							<SubMainContainer>
								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Business KYC Request Pending</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>
												{DashboardData.businessProfileKycRequestsPendingCount}
											</HeadText>
										</InnerContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Not Assigned</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>
												{DashboardData.businessProfileKycRequestsPendingCountAssigned}
											</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.businessProfileKycRequestsPending?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													title: item.title,
												};
											})}
											columns={columnsForProperty}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "business-kyc",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>
								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Business KYC Request Untouched</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>
												{DashboardData.businessProfileKycRequestsUntouchedCount}
											</HeadText>
										</InnerContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Not Assigned</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>
												{DashboardData.businessProfileKycRequestsUntouchedCountAssigned}
											</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.businessProfileKycRequestsUntouched?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													title: item.title,
												};
											})}
											columns={columnsForProperty}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "business-kyc",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>
							</SubMainContainer>
							<SubMainContainer>
								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>User KYC Request Pending</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.userKycRequestsPendingCount}</HeadText>
										</InnerContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Not Assigned</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.userKycRequestsPendingCountAssigned}</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.userKycRequestsPending?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													title: item.first_name + " " + item.last_name,
												};
											})}
											columns={columnsForProperty}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "user-kyc",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>
								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>User KYC Request Untouched</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.userKycRequestsUntouchedCount}</HeadText>
										</InnerContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Not Assigned</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>
												{DashboardData.userKycRequestsUntouchedCountAssigned}
											</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.userKycRequestsUntouched?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													title: item.first_name + " " + item.last_name,
												};
											})}
											columns={columnsForProperty}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "user-kyc",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>
							</SubMainContainer>
							<SubMainContainer>
								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Bug Reports</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.bugReportCountCount}</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.bugReportCount?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													url: item.url,
												};
											})}
											columns={columnsForBugReport}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "bug-report",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>
								<SubContainer>
									<SubMainContainer>
										<InnerContainer>
											<HeadText sx={{ fontSize: "1rem" }}>Reports</HeadText>
											<HeadText sx={{ fontSize: "3rem" }}>{DashboardData.reportCountCount}</HeadText>
										</InnerContainer>
									</SubMainContainer>
									<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
										<DataGridPremium
											disableRowSelectionOnClick={false}
											rows={DashboardData.reportCount?.map((item: any) => {
												return {
													id: item._id,
													_id: item._id,
													report_type: item.report_type,
												};
											})}
											columns={columnsForReport}
										/>
									</Box>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#F76C35",
											color: "#FFFFFF",
											width: "8rem",
											marginTop: "1rem",
										}}
										onClick={() => {
											router.push(
												{
													pathname: router.pathname,
													query: {
														...router.query,
														tab: "report",
														page: paginationModel.page,
													},
												},
												undefined,
												{
													shallow: true,
												},
											);
										}}
									>
										View All
									</Button>
								</SubContainer>
							</SubMainContainer>

							<Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
								<DataGridPremium
									disableRowSelectionOnClick={false}
									slots={{ toolbar: GridToolbar }}
									// onCellClick={(cellValues) => {
									//   console.log(cellValues);
									//   setOpenMoadal(true);
									//   setBugData(cellValues.row.action);
									// }}
									rows={dashboardData?.map((item: any) => {
										return {
											id: item._id,
											log_id: item._id,
											log_creator_type: item.logs?.created_by?.is_business_profile
												? "Business Profile"
												: "User Profile",
											log_creator: item.logs?.created_by
												? item.logs?.created_by.is_business_profile
													? item.logs?.created_by.business_profile_id?.title
													: item.logs?.created_by.user_id?.first_name + " " + item.logs?.created_by.user_id?.last_name
												: "",
											action: item.logs?.action,
											entity_type: item.logs?.entity?.entity_type,
											entity: item.logs?.entity,

											action_performed_at: item.action_performed_at,
										};
									})}
									// rows={bugdata}
									columns={columns}
									pagination={true}
									paginationMode="server"
									onPaginationModelChange={(paginationModel) => {
										setPaginationModel(paginationModel);
									}}
									rowCount={rowCountState}
									pageSizeOptions={[100]}
									filterMode="server"
									onFilterModelChange={(newFilterModel) => {
										setQueryOptions(newFilterModel);
										FilteringFunction(newFilterModel);
									}}
									sx={{
										backgroundColor: "#FFFFFF",
										"& > .MuiDataGrid-columnSeparator": {
											display: "none",
										},
										"& .MuiDataGrid-cell": {
											borderBottom: "none",
										},
									}}
								/>
							</Box>
						</>
					)
				) : (
					<YouDontHaveAccess>
						<Typography
							variant="h6"
							color="text.secondary"
						>
							You don&apos;t have access to this page
						</Typography>
					</YouDontHaveAccess>
				)}
			</MainInnerContainer>
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default ActivityDatatable;
