/* common import */
import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

/* component import */

/* styling */

const SliderBar = styled(Slider)(({ theme }) => ({
  // [theme.breakpoints.down("sm")]: {
  //   width: "100%",
  // },
  ".MuiSlider-thumb": {
    borderRadius: "0.126rem",
    height: "1rem",
    width: "1rem",
    backgroundColor: theme.palette.mode == "dark" ? "#FFF" : "#FFF",
    border: "1px solid #FF8C3E",
  },
  ".MuiSlider-rail": {
    opacity: 6,
    borderRadius: "4px",
    border: "3px solid transparent",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
    color: theme.palette.mode == "dark" ? " #ffffff" : " #ffffff",
  },
  ".MuiSlider-track": {
    height: "0.5rem",
    color:
      theme.palette.mode == "dark"
        ? " linear-gradient(138.59deg, #EF502C -15.15%, #FF8C3E 96.22%)"
        : " linear-gradient(138.59deg, #EF502C -15.15%, #FF8C3E 96.22%)",
  },
  ".MuiSlider-root": {
    height: "1rem",
    top: "5rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
}));

const BoxContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  width: "30%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const SliderRadius = (props: any) => {
  return (
    <React.Fragment>
      <BoxContainer>
        <Typography id="discrete-slider">Radius</Typography>
        <SliderBar
          size="small"
          value={props.radius}
          aria-label="Small"
          valueLabelDisplay="auto"
          onChange={props.handleChange}
          // marks={marks}
          step={0.01}
          // min={0}
          // max={50}
        />
      </BoxContainer>
    </React.Fragment>
  );
};
export default SliderRadius;
