/* Common Imports */

import React from "react";

/* Component Imports */

import { styled } from "@mui/system";

const FooterLogoDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const MailIcon = styled("img")(({ theme }) => ({}));

const FooterLogo = () => {
  return (
    <React.Fragment>
      <FooterLogoDiv>
        <MailIcon
          src="/images/logo/logo.svg"
          alt="Footer Logo"
          width={120}
          height={40}
          loading="lazy"
          referrerPolicy="no-referrer"
        />
      </FooterLogoDiv>
    </React.Fragment>
  );
};

export default FooterLogo;
