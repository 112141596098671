/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Add, NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Divider, Link, Switch, Typography } from "@mui/material";
import { useRouter } from "next/router";
import AddEmployeeModal from "../../modal/add-employee";
import EditEmployeeModal from "../../modal/edit-employeeModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const EmployeesDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a constant for router, let's use ref to get the current value of router

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the modal for loading

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	/*

  & Next, let's define a local state for the modal for access level

  */

	const [openAccessModal, setOpenAccessMoadal] = React.useState(false);

	const [data, setData] = React.useState();

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
	}, [DashboardData]);

	/*

  & Next, let's define a function to close the access level modal

  */

	const handleClose = () => {
		setOpenAccessMoadal(false);
	};
	const [editModal, setEditMoadal] = React.useState(false);

	const handleEditClose = () => {
		setEditMoadal(false);
	};

	/*

  & Next, let's define a function to remove admin access

  */

	const remove = async (cellValues: any) => {
		console.log(cellValues);
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "employees-table/delete-employee", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				employee_id: cellValues.row.employeeId,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
				},
			},
			undefined,
			{
				shallow: true,
			},
		);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to remove admin access

  */

	const testChange = async (e: any,cellValues: any ) => {
		console.log(cellValues,"fff");
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "employees-table/update-test", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				_id: cellValues.id,
				test: e.target.checked,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
				},
			},
			undefined,
			{
				shallow: true,
			},
		);

		setOpenMoadal(false);
	};
	/*

  & Next, let's define a function to handle the click on grant access button

  */

	const handleClickGrandAccess = () => {
		setOpenAccessMoadal(true);
	};

	/*

  & Next, let's define the data for the menu items

  */

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "employeeId",
			headerName: "Employee ID",
			width: 250,
		},
		{
			field: "firstname",
			headerName: "First Name",
			width: 100,
		},
		{
			field: "lastname",
			headerName: "Last Name",
			width: 100,
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 200,
		},
		{
			field: "email",
			headerName: "Email",
			width: 150,
		},
		{
			field: "department",
			headerName: "Department",
			width: 150,
		},
		{
			field: "designation",
			headerName: "Designation",
			width: 150,
		},
		{
			field: "test",
			headerName: "Test",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							testChange(e, cellValues.row);
						}}
					/>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<ButtonContainer>
						<Button
							variant="outlined"
							color="primary"
							sx={{
								borderRadius: "0.5rem",
							}}
							onClick={() => {
								setEditMoadal(true);
								setData(cellValues.row);
							}}
						>
							Edit
						</Button>
						<Button
							variant="contained"
							color="primary"
							sx={{
								borderRadius: "0.5rem",
							}}
							onClick={() => {
								remove(cellValues);
							}}
						>
							Remove
						</Button>
					</ButtonContainer>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<Button
					variant="outlined"
					color="primary"
					startIcon={<Add />}
					sx={{
						borderRadius: "0.5rem",
						margin: "1rem 1rem 0rem 1rem",
					}}
					onClick={() => {
						handleClickGrandAccess();
					}}
				>
					Add Employee
				</Button>
			</ButtonContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on breadcrumb

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Emplyees
		</Typography>,
	];

	return (
		<MainContainer>
			<AddEmployeeModal
				open={openAccessModal}
				onClose={handleClose}
			/>
			<EditEmployeeModal
				open={editModal}
				onClose={handleEditClose}
				data={data}
			/>
			<HeadContainer>
				<HeadText>Employees</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData ? (
				DashboardData.accessLevel ? (
					(DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("employees")) ||
					DashboardData.accessLevel.includes("super_admin") ? (
						DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
							<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
								<DataGridPremium
									disableRowSelectionOnClick={false}
									slots={{ toolbar: GridToolbar }}
									rows={DashboardData.DashboardData?.map((item: any) => {
										return {
											id: item._id,
											employeeId: item.employee_id,
											firstname: item.first_name,
											lastname: item.last_name,
											phonenumber: item.contact
												? item.contact.work.country_code + "" + item.contact.work.phone
												: "Not Available",
											department: item.department,
											designation: item.designation,
											email: item.contact ? item.contact.work.email : "Not Available",
											test: item.test,
										};
									})}
									columns={columns}
									pagination={true}
									paginationMode="server"
									onPaginationModelChange={(paginationModel) => {
										setPaginationModel(paginationModel);
									}}
									rowCount={rowCountState}
									pageSizeOptions={[100]}
									sx={{
										backgroundColor: "#FFFFFF",
										"& > .MuiDataGrid-columnSeparator": {
											display: "none",
										},
										"& .MuiDataGrid-cell": {
											borderBottom: "none",
										},
									}}
								/>
							</Box>
						)
					) : (
						<YouDontHaveAccess>
							<Typography
								variant="h6"
								color="text.secondary"
							>
								You don&apos;t have access to this page
							</Typography>
						</YouDontHaveAccess>
					)
				) : (
					<YouDontHaveAccess>
						<Typography
							variant="h6"
							color="text.secondary"
						>
							You don&apos;t have access to this page
						</Typography>
					</YouDontHaveAccess>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default EmployeesDatatable;
