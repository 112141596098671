/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Add, ArrowDropDown, NavigateNext } from "@mui/icons-material";
import {
	Box,
	Breadcrumbs,
	Button,
	Chip,
	debounce,
	Divider,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	Typography,
	useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import LoadingModal from "../../../modal/loading";
import UpdateServiceReminderModal from "../../../modal/updateServiceReminderModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "untouched"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "work_in_progress"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: id === "pending_qc"
				? {
						backgroundColor: "rgba(237, 108, 2, 0.5)",
					}
				: id === "published"
					? {
							backgroundColor: "rgba(76, 175, 80, 0.5)",
						}
					: id === "publish_requested"
						? {
								backgroundColor: "rgb(255, 191, 0)",
							}
						: id === "edited"
							? {
									backgroundColor: "#d5d5d7",
									color: "#000000",
								}
							: {
									backgroundColor: "rgba(255, 255, 255, 0.002)",
								},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const ServiceDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [reminderModal, setReminderModal] = React.useState(false);

	const [serviceId, setServiceId] = React.useState("");

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	/*

  & Next, let's define a function to handle the change in the select and update the status

  */

	const handleChange = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "service-table/service-status", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				status: event.target.value,
				serviceId: cellValues.id,
				pre_status: cellValues.row.status,
				email: session ? (session.user ? session.user.email : "") : "",
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const handleChangeAssigned = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "service-table/service-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				assigned_to: event.target.value,
				serviceId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete the assigned to.

  */

	const deleteAssigned = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "service-table/service-delete-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deletedAssignedTo: item,
				serviceId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "service-table/service-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const showInFeatured = async (e: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "service-table/service-show-in-featured", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				show_in_featured: e.target.checked,
				serviceId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the data for the menu items

  */

	const menuItems = [
		{
			id: 1,
			label: "Untouched",
			value: "untouched",
		},
		{
			id: 2,
			label: "Work in progress",
			value: "work_in_progress",
		},
		{
			id: 3,
			label: "Pending QC",
			value: "pending_qc",
		},
		{
			id: 4,
			label: "Published",
			value: "published",
		},
		{
			id: 5,
			label: "Publish Requested",
			value: "publish_requested",
		},
		{
			id: 6,
			label: "Edited",
			value: "edited",
		},
	];

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "serviceId",
			headerName: "Service ID",
			width: 150,
		},
		{
			field: "serviceName",
			headerName: "Service Name",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/services/" + cellValues.row.action}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "slug",
			headerName: "Slug",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/services/" + cellValues.row.action}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 200,
		},
		{
			field: "username",
			headerName: "Username",
			width: 200,
			// renderCell: (cellValues: any) => {
			//   return (
			//     <Link
			//       href="/"
			//       sx={{
			//         textDecoration: "none",
			//       }}
			//     >
			//       {cellValues.value}
			//     </Link>
			//   );
			// },
		},
		{
			field: "status",
			headerName: "Status",
			width: 200,
			editable: true,
			// type: "singleSelect",
			valueOptions: menuItems,
			valueFormatter: (params: any) => {
				if (!params) {
					return "untouched";
				}
				return params.value === "untouched"
					? "Untouched"
					: params.value === "work_in_progress"
						? "Work in progress"
						: params.value === "pending_qc"
							? "Pending QC"
							: params.value === "published"
								? "Published"
								: params.value === "publish_requested"
									? "Publish Requested"
									: params.value === "edited"
										? "Edited"
										: "";
			},
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((item: any) => item._id === cellValues.row.id);
				return (
					<ChipData
						id={data ? data.status : cellValues.value}
						label={
							(data ? data.status : cellValues.value) === "untouched"
								? "Untouched"
								: (data ? data.status : cellValues.value) === "work_in_progress"
									? "Work in progress"
									: (data ? data.status : cellValues.value) === "pending_qc"
										? "Pending QC"
										: (data ? data.status : cellValues.value) === "published"
											? "Published"
											: (data ? data.status : cellValues.value) === "publish_requested"
												? "Publish Requested"
												: (data ? data.status : cellValues.value) === "edited"
													? "Edited"
													: ""
						}
						variant="filled"
						onDelete={() => {}}
						deleteIcon={<ArrowDropDown />}
					/>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((property: any) => property._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						value={data ? data.status : cellValues.value}
						onChange={(event: any) => handleChange(event, cellValues)}
					>
						{menuItems.map((item: any, index: number) => {
							return (
								<MenuItem
									value={item.value}
									key={index}
								>
									{item.label}
								</MenuItem>
							);
						})}
					</Select>
				);
			},
		},
		{
			field: "show_in_featured",
			headerName: "Show In Featured",
			width: 150,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							showInFeatured(e, cellValues);
						}}
					/>
				);
			},
		},

		{
			field: "assigned",
			headerName: "Assigned",
			width: 300,
			editable: true,
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				return data && data.assigned_to?.length > 0 ? (
					<ActionActions>
						{data &&
							data.assigned_to?.map((item: any, index: number) => {
								return (
									<Chip
										id={item._id}
										key={index}
										label={
											item.first_name + " " + item.last_name ? item.first_name + " " + item.last_name : "Assign to"
										}
										variant="outlined"
										onDelete={() => {
											deleteAssigned(item._id, cellValues);
										}}
										color="primary"
									/>
								);
							})}
					</ActionActions>
				) : (
					<Chip
						id="assign"
						label="Assign to"
						variant="outlined"
						onDelete={() => {}}
						deleteIcon={<Add />}
						color="primary"
					/>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						onChange={(event: any) => handleChangeAssigned(event, cellValues)}
						value={data ? data.assigned_to : cellValues.value}
					>
						{DashboardData.list_of_moderators.map((item: any, index: number) => {
							return (
								<MenuItem
									value={item._id}
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
									}}
								>
									{item.first_name + " " + item.last_name}
								</MenuItem>
							);
						})}
					</Select>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Link
							href={"tel:+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/call_blue.svg"
										: "/images/icons/social/call_blue.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://wa.me/+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/whatsapp_green.svg"
										: "/images/icons/social/whatsapp_green.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://beegru.com/services/" + cellValues.row.action}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={theme.palette.mode === "dark" ? "/images/icons/social/edit.svg" : "/images/icons/social/edit.svg"}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						{cellValues?.row?.status === "publish_requested" ? (
							<Button
								variant="contained"
								color="primary"
								sx={{
									padding: "0.5rem 1rem",
									textTransform: "none",
									borderRadius: "1rem",
									fontSize: "0.75rem",
								}}
								onClick={() => {
									setReminderModal(true);
									setServiceId(cellValues.row.id);
								}}
							>
								remind
							</Button>
						) : null}
					</ActionActions>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Link
			underline="hover"
			key="2"
			color="inherit"
			href="#"
			onClick={handleClick}
		>
			Moderation
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Services
		</Typography>,
	];

	return (
		<MainContainer>
			<UpdateServiceReminderModal
				open={reminderModal}
				onClose={() => {
					setReminderModal(false);
				}}
				serviceId={serviceId}
			/>
			<LoadingModal open={openModal} />
			<HeadContainer>
				<HeadText>Moderation</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("service")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any) => {
								return {
									id: item._id,
									serviceId: item._id,
									serviceName: item.title,
									phonenumber: item.created_by
										? item.created_by.user_id
											? item.created_by.user_id.country_code
												? item.created_by.user_id.country_code + " " + item.created_by.user_id.phone
												: "+91 " + item.created_by.user_id.phone
											: "no phone"
										: "no phone",
									slug: item.slug,
									username: item.created_by?.user_id?.first_name + " " + item.created_by?.user_id?.last_name,
									status: item.status == "active" ? "Untouched" : item.status,
                                    show_in_featured: item.show_in_featured,
									assigned: item.assigned_to,
									action: item.slug,
								};
							})}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								FilteringFunction(newFilterModel);
							}}
							sx={{
								backgroundColor: "#FFFFFF",
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default ServiceDataTable;
