/* Common Imports */

import React from "react";

/* Component Imports */

import { styled } from "@mui/system";
import { Link, useTheme } from "@mui/material";

const MailIcon = styled("img")(({ theme }) => ({
  margin: "0rem 0.5rem 0rem 0.5rem",
}));

const AddressLink = styled(Link)(({ theme }) => ({}));
const SocialRow = styled("div")(({ theme }) => ({}));

const FooterSocialIcons = (props: any) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <SocialRow>
        <AddressLink
          href="//facebook.com/beegrurealty"
          rel="noopener"
          title="Follow Beegru on Facebook"
          target="_blank"
        >
          <MailIcon
            src={
              theme.palette.mode == "dark"
                ? "/images/icons/social/facebook-white.svg"
                : "/images/icons/social/facebook.svg"
            }
            alt="Facebook Icon"
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
        </AddressLink>
        <AddressLink
          href="//twitter.com/beegrurealty"
          rel="noopener"
          title="Follow Beegru on Twitter"
          target="_blank"
        >
          <MailIcon
            src={
              theme.palette.mode == "dark"
                ? "/images/icons/social/twitter-white.svg"
                : "/images/icons/social/twitter.svg"
            }
            alt="Twitter Icon"
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
        </AddressLink>
        <AddressLink
          href="//instagram.com/beegrurealty"
          rel="noopener"
          title="Follow Beegru on Instagram"
          target="_blank"
        >
          <MailIcon
            src={
              theme.palette.mode == "dark"
                ? "/images/icons/social/instagram-white.svg"
                : "/images/icons/social/instagram.svg"
            }
            alt="Instagram Icon"
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
        </AddressLink>
        <AddressLink
          href="//linkedin.com/company/beegru"
          rel="noopener"
          title="Follow Beegru on Linkedin"
          target="_blank"
        >
          <MailIcon
            src={
              theme.palette.mode == "dark"
                ? "/images/icons/social/linkedin-white.svg"
                : "/images/icons/social/linkedin.svg"
            }
            alt="Linkedin Icon"
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
        </AddressLink>
      </SocialRow>
    </React.Fragment>
  );
};

export default FooterSocialIcons;
