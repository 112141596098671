import { NextRouter, withRouter } from "next/router";
import Head from "next/head";
import React from "react";

const Header = ({
  router,
  keywords,
  description,
  title,
  canonicalUrl,
}: {
  router: NextRouter;
  keywords?: string;
  description?: string;
  title?: string;
  canonicalUrl?: string;
}) => {
  return (
    <React.Fragment>
      <Head>
        <link
          rel="preconnect"
          href={process.env.PRODUCTION_URL}
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href={process.env.CDN_URL}
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href={process.env.API_CDN_URL}
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://google.com"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://www.googletagmanager.com"
          crossOrigin="anonymous"
        />
        <link
          rel="preconect"
          href="https://www.google-analytics.com"
          crossOrigin="anonymous"
        />
        <link
          rel="canonical"
          href={
            canonicalUrl
              ? canonicalUrl
              : process.env.PRODUCTION_URL + router.asPath.substring(1)
          }
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="cache-control" content="Private" />
        <meta httpEquiv="Expires" content="31536000" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#068200" />
        <meta
          name="Description"
          content={
            description
              ? description
              : "Beegru Marketplace is a geographical data aggregator. It collects information about properties across the world from various sources such as real estate agents, developers and land owners to create a comprehensive layer of property information on top of consumer mapping platforms such as Google Maps."
          }
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="keywords"
          content={
            keywords
              ? keywords
              : "marketplace,real,estate,location,agent,developer,professional,land,owner,rent,buy,sell,lease,open,for,business"
          }
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title ? title : "Beegru"} />
        <meta property="og:title" content={title ? title : "Beegru"} />
        <meta
          property="og:description"
          content={
            description
              ? description
              : "Beegru Marketplace is a geographical data aggregator. It collects information about properties across the world from various sources such as real estate agents, developers and land owners to create a comprehensive layer of property information on top of consumer mapping platforms such as Google Maps."
          }
        />
        <meta property="og:url" content={process.env.PRODUCTION_URL} />
        <meta
          property="og:image"
          content={process.env.CDN_URL + "images/icons/logo/og_512.jpg"}
        />
        <meta
          property="og:image:alt"
          content={process.env.CDN_URL + "images/icons/logo/og_512.jpg"}
        />
        <meta
          property="og:image:secure_url"
          content={process.env.CDN_URL + "images/icons/logo/og_512.jpg"}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:locale" content="en_GB" />
        <link
          rel="icon"
          href={process.env.CDN_URL + "images/icons/logo/favicon_48.png"}
        />
        <link
          rel="shortcut icon"
          type="image/png"
          href={process.env.CDN_URL + "images/icons/logo/favicon_48.png"}
        />
        <link
          rel="apple-touch-icon"
          href={process.env.CDN_URL + "images/icons/logo/favicon_192.png"}
        />
        <link rel="manifest" href={process.env.CDN_URL + "manifest.json"} />
        <title>{title ? title : "Beegru Control Panel"}</title>
      </Head>
    </React.Fragment>
  );
};

export default withRouter(Header);
