import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton, Link, Typography, styled } from "@mui/material";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
	padding: "1.5rem",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
   
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
    height: "25rem",
	gap: "1.5rem",
    overflow:"scroll",
    scrollbarWidth: "none",
}));

const ContainerOne = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	borderRight: "1px solid #E0E0E0",
	padding: "0rem 1.5rem 0rem 0rem",
	gap: "0.25rem",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "row",
		gap: "0.75rem",
	},
}));

const ImageContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.75rem",
	color: "#00000099",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: "500",
	lineHeight: "1.251rem",
	color: "#000000",
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const SearchResultsModal = ({ open, onClose, searchResults }: { open: any; onClose: any; searchResults: any }) => {
	const businessProfiles = searchResults ? searchResults.businessProfiles : [];
	const properties = searchResults ? searchResults.properties : [];
	const services = searchResults ? searchResults.services : [];

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						color: "primary.main",
					}}
				>
					search results
					<Close
						aria-label="close"
						onClick={onClose}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<Container>
					<InnerContainer>
						<ContainerOne>
							<HeadText>User Profile</HeadText>
							<ImageContainer>
								<Link
									href={`https://beegru.com/users/${searchResults?._id}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									{searchResults?._id}
								</Link>
							</ImageContainer>
						</ContainerOne>
						<ContainerOne>
							<HeadText>Business Profiles</HeadText>
							{businessProfiles.map((profile: any, index: number) => (
								<ImageContainer key={index}>
									<Link
										href={`https://beegru.com/${profile.business_profile_type}s/${profile.slug}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										{profile.title}{" "}
									</Link>
								</ImageContainer>
							))}
						</ContainerOne>
						<ContainerOne>
							<HeadText>Properties</HeadText>
							{properties.map((property: any, index: number) => (
								<ImageContainer key={index}>
									<Link
										href={`https://beegru.com/properties/${property.slug}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										{property.title}{" "}
									</Link>
								</ImageContainer>
							))}
						</ContainerOne>
						<ContainerOne>
							<HeadText>Services</HeadText>
							{services.map((service: any, index: number) => (
								<ImageContainer key={index}>
									<Link
										href={`https://beegru.com/services/${service.slug}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										{service.title}{" "}
									</Link>
								</ImageContainer>
							))}
						</ContainerOne>
					</InnerContainer>
				</Container>
			</Dialog>
		</div>
	);
};

export default SearchResultsModal;
