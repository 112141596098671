import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useRouter } from "next/router";

const MainContainer = styled(DialogContentText)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "1rem",
}));

const Close = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(0.5),
  top: theme.spacing(0.5),
  color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  height: "4rem",
  width: "100%",
}));

const AddEmployeeModal = ({ open, onClose }: { open: any; onClose: any }) => {
  const [phone, setPhone] = React.useState("");
  const [country_code, setCountryCode] = React.useState("+91");
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [employee_id, setEmployeeId] = React.useState("");

  const router = React.useRef(useRouter()).current;

  const submit = async () => {
    await fetch(
      process.env.PRODUCTION_API_URL + "employees-table/add-employee",
      {
        method: "POST",
        cache: "no-store",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({
          phone: phone,
          country_code: country_code,
          first_name: first_name,
          last_name: last_name,
          email: email,
          department: department,
          designation: designation,
          employee_id: employee_id,
        }),
      }
    );
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          change: "true",
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
    setDepartment("");
    setDesignation("");
    setEmployeeId("");
    setEmail("");
    setFirstName("");
    setLastName("");
    setPhone("");
    onClose();
  };

  const [employeIdError, setEmployeeIdError] = React.useState(false);
  const [emplyeeIdHelperText, setEmployeeIdHelperText] = React.useState(
    "Enter the employee ID."
  );
  const [employeeIDColor, setEmployeeIDColor] = React.useState<
    "primary" | "secondary" | "error" | "info" | "success" | "warning"
  >("primary");

  return (
    <div>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onClose}
      >
        <DialogTitle>Add Employee Details</DialogTitle>
        <Container>
          <Close aria-label="close" onClick={onClose}>
            <CloseIcon />
          </Close>
        </Container>
        <DialogContent>
          <MainContainer>
            <TextField
              id="outlined-basic"
              label="First Name"
              type="text"
              variant="outlined"
              value={first_name}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Last Name"
              type="text"
              variant="outlined"
              value={last_name}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Email"
              type="text"
              variant="outlined"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Department"
              type="text"
              variant="outlined"
              value={department}
              onChange={(event) => {
                setDepartment(event.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Designation"
              type="text"
              variant="outlined"
              value={designation}
              onChange={(event) => {
                setDesignation(event.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Employee ID"
              type="text"
              variant="outlined"
              inputProps={{
                pattern: "BGRUd{3}",
                maxLength: "7",
              }}
              value={employee_id}
              error={employeIdError}
              helperText={emplyeeIdHelperText}
              color={employeeIDColor}
              onChange={(event) => {
                const matchExpression = new RegExp(/BGRU\d{3}/g);
                if (!event.target.value.match(matchExpression)) {
                  setEmployeeIdError(true);
                  setEmployeeIdHelperText("This employee ID is not valid.");
                  setEmployeeIDColor("error");
                } else {
                  setEmployeeIdError(false);
                  setEmployeeIdHelperText("This is a valid employee ID.");
                  setEmployeeIDColor(
                    event.target.value.length === 7 ? "success" : "primary"
                  );
                }
                setEmployeeId(event.target.value);
              }}
            />

            <TextField
              id="outlined-basic"
              label="Phone Number"
              type="number"
              variant="outlined"
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
          </MainContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            cancel
          </Button>
          <Button
            autoFocus
            disabled={
              employeIdError ||
              phone === "" ||
              first_name === "" ||
              last_name === "" ||
              email === "" ||
              department === "" ||
              designation === "" ||
              employee_id === ""
            }
            onClick={() => {
              submit();
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddEmployeeModal;
