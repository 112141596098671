/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we need from MUI

*/

import { AppBar, Button, IconButton, InputAdornment, OutlinedInput, Toolbar, Typography } from "@mui/material";

/*

? Next, let's import all the icons we need in this component

*/

import { Google, Pageview as PageviewIcon } from "@mui/icons-material";

/*

? Next, let's import all the utility components we need

*/

import { signIn, signOut } from "next-auth/react";
import Link from "next/link";
import SearchResultsModal from "../modal/searchResultsModal";

/*

& Next, let's write a type to describe the extra props of the Navbar component

*/

type ExtraPropsForNavbar = {
	enableTranslucency?: boolean;
};
/*

& Next, let's create styled components for all the default MUI components we imported

*/

const StyledAppBar = styled(AppBar)(({ theme }) => ({})) as typeof AppBar;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({})) as typeof Toolbar;

const StyledTitle = styled(Typography)(({ theme }) => ({
	color: "#F76C35",
	fontSize: "1.5rem",
	fontWeight: 600,
	textTransform: "uppercase",
	letterSpacing: "0rem",
	lineHeight: "1.875rem",
})) as typeof Typography;

const StyledTitle2 = styled(Typography)(({ theme }) => ({
	color: "#F76C35",
	fontSize: "1.5rem",
	fontWeight: 600,
	textTransform: "uppercase",
	letterSpacing: "0rem",
	lineHeight: "1.875rem",
	[theme.breakpoints.down(theme.breakpoints.values["xs"] + 40)]: {
		fontSize: "1.25rem",
	},
})) as typeof Typography;

const StyledLinkForTitle = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "flex-start",
	gap: "0.5rem",
	[theme.breakpoints.down("sm910")]: {
		display: "none",
	},
	[theme.breakpoints.down(theme.breakpoints.values["xs"] + 40)]: {
		display: "flex",
	},
}));

const StyledLinkForTitle2 = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "flex-start",
	gap: "0.5rem",
	margin: "0rem 0rem 0rem 6.75rem",
	[theme.breakpoints.down(theme.breakpoints.values["xs"] + 40)]: {
		display: "none",
	},
	[theme.breakpoints.down("sm")]: {
		margin: "0px 0px 0px 12px",
	},
	[theme.breakpoints.up("sm910")]: {
		display: "none",
	},
}));

const Navbar = styled("nav", {
	shouldForwardProp: (prop) => prop !== "enableTranslucency",
})<ExtraPropsForNavbar>(({ theme, enableTranslucency }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	height: "4rem",
	padding: "0.5rem 0.5rem 0.5rem 0.5rem",
	backgroundColor: enableTranslucency ? "transparent" : "#FFFFFF",
	backdropFilter: enableTranslucency ? "blur(1rem)" : "blur(0px)",
}));

const Logo = styled("img")(({ theme }) => ({
	width: "100%",
	height: "100%",
	scale: "1.25",
}));

const LogoContainer = styled("div")(({ theme }) => ({
	width: "3rem",
	height: "3rem",
	borderRadius: "3rem",
	overflow: "hidden",
	[theme.breakpoints.down("sm910")]: {
		display: "none",
	},
}));

const LogoContainer2 = styled("div")(({ theme }) => ({
	width: "3rem",
	height: "3rem",
	borderRadius: "3rem",
	overflow: "hidden",
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
	[theme.breakpoints.up("sm910")]: {
		display: "none",
	},
}));

const LogoContainer3 = styled("div")(({ theme }) => ({
	width: "3rem",
	height: "3rem",
	borderRadius: "3rem",
	overflow: "hidden",
	[theme.breakpoints.down(theme.breakpoints.values["xs"] + 40)]: {
		display: "none",
	},
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const LinkContainer = styled("div")(({ theme }) => ({
	display: "none",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	[theme.breakpoints.up("sm")]: {
		display: "flex",
		gap: "1rem",
	},
	[theme.breakpoints.up("md")]: {
		display: "flex",
		gap: "1rem",
	},
	[theme.breakpoints.up("lgPlus")]: {
		display: "flex",
		gap: "1.5rem",
	},
	[theme.breakpoints.up("xl")]: {
		display: "flex",
		gap: "2.5rem",
	},
}));

const SocialLinkContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	gap: "0.5rem",
}));

const StyledMenuIconButton = styled(IconButton)(({ theme }) => ({
	backgroundColor: "#F76C35",
	"&:hover": {
		backgroundColor: "#F76C35",
	},
	color: "#ffffff",
	borderRadius: "0.25rem",
	width: "2.25rem",
	height: "2.25rem",
	[theme.breakpoints.up("sm910")]: {
		display: "none",
	},
})) as typeof IconButton;

const MenuAndLogoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const StyledUsername = styled(Typography)(({ theme }) => ({
	color: "#000000",
	fontSize: "1.25rem",
	fontWeight: 600,
	textAlign: "right",
})) as typeof Typography;

const StyledUseremail = styled(Typography)(({ theme }) => ({
	color: "#000000",
	fontSize: "0.9rem",
	fontWeight: 400,
	textAlign: "right",
})) as typeof Typography;

const StyledUserDetailsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-end",
}));

const SearchContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	// width: "15rem",
	// gap: "0.5rem",
}));

const LayoutHeader = ({ session }: { session?: any }) => {
	/*

  & Next, let's create a local state to hold the current scroll position

  */

	const [scrollPosition, setScrollPosition] = React.useState<number>(0);

	const [phoneNumber, setPhoneNumber] = React.useState<string>("");

	const [searchResults, setSearchResults] = React.useState<any>();

	const [modalOpen, setModalOpen] = React.useState<boolean>(false);

	const handleSearch = () => {
		fetch(process.env.PRODUCTION_API_URL + `search-phone-number`, {
			method: "POST",
			headers: {
				"Content-Type": "text/plain",
			},
			body: JSON.stringify({ phone_number: phoneNumber }),
		})
			.then((response) => response.json())
			.then((data) => {
				setSearchResults(data ? data.payload[0] : null);
				setModalOpen(data ? (data.payload ? true : false) : false);
			});
	};

	/*

  & Next, let's write a function to update the scroll position state on change of the scroll position

  */

	const onChangeScrollPosition: () => void = () => {
		setScrollPosition(window.scrollY);
	};

	/*

  & Next, let's write a trigger-once useEffect hook that will register and remove our scroll event listener

  */

	React.useEffect(() => {
		window.addEventListener("scroll", onChangeScrollPosition, {
			passive: true,
		});
		return () => {
			window.removeEventListener("scroll", onChangeScrollPosition);
		};
	}, []);

	return (
		<React.Fragment>
			<SearchResultsModal
				open={modalOpen}
				onClose={() => {
					setModalOpen(false);
				}}
				searchResults={searchResults}
			/>
			<StyledAppBar
				position="fixed"
				color="transparent"
				elevation={scrollPosition === 0 ? 0 : 1}
			>
				<Navbar enableTranslucency={scrollPosition !== 0}>
					<MenuAndLogoContainer>
						<StyledLinkForTitle
							href="/"
							rel="noopener"
							aria-label="Beegru Marketplace Control Panel"
							title="Beegru Marketplace Control Panel"
						>
							<LogoContainer>
								<Logo
									src={"images/icons/logo/new_logo.jpeg"}
									alt="Beegru Marketplace Control Panel"
									width={48}
									height={48}
								/>
							</LogoContainer>
							<StyledTitle
								variant="h1"
								component="span"
							>
								Beegru Marketplace Control Panel
							</StyledTitle>
						</StyledLinkForTitle>
					</MenuAndLogoContainer>
					<StyledLinkForTitle2
						href="/"
						rel="noopener"
						aria-label="Beegru Marketplace Control Panel"
						title="Beegru Marketplace Control Panel"
					>
						<LogoContainer2>
							<Logo
								src={(process.env.CDN_URL || "") + "images/icons/logo/favicon_192.png"}
								alt="Beegru Marketplace Control Panel"
								width={48}
								height={48}
							/>
						</LogoContainer2>
						<StyledTitle
							variant="h1"
							component="h1"
						>
							Beegru Marketplace Control Panel
						</StyledTitle>
					</StyledLinkForTitle2>
					{session ? (
						<SearchContainer>
							<OutlinedInput
								sx={{
									height: "2rem",
									padding: "0.5rem",
									"& .MuiOutlinedInput-input": {
										padding: "0rem 0rem 0rem 0rem",
										background: "#FFFFFF",
									},
								}}
								id="outlined-adornment-password"
								placeholder="Phone Number"
								value={phoneNumber}
								onChange={(e) => {
									const numericValue = e.target.value.replace(/\D/g, "");
									console.log(numericValue, "jzshj");
									if (numericValue.length > 10) {
										return;
									}
									setPhoneNumber(numericValue);
								}}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleSearch}
											edge="end"
										>
											<PageviewIcon
												sx={{
													color: "#F76C35",
													fontSize: "2rem",
												}}
											/>
										</IconButton>
									</InputAdornment>
								}
							/>
						</SearchContainer>
					) : null}

					<LogoContainer3>
						<Logo
							src={(process.env.CDN_URL || "") + "images/icons/logo/favicon_192.png"}
							alt="Beegru Marketplace Control Panel"
							width={48}
							height={48}
						/>
					</LogoContainer3>
					<LinkContainer>
						<SocialLinkContainer>
							{/* <StyledUserDetailsContainer>
                <StyledUsername variant="body1" component="span">
                  {session ? (session.user ? session.user.name : "") : ""}
                </StyledUsername>
                <StyledUseremail variant="body1" component="span">
                  {session ? (session.user ? session.user.email : "") : ""}
                </StyledUseremail>
              </StyledUserDetailsContainer>
              {session ? (
                session.user ? (
                  <img
                    src={
                      session ? (session.user ? session.user.image : "") : ""
                    }
                    alt="User"
                    width={48}
                    height={48}
                    style={{
                      borderRadius: "0.25rem",
                    }}
                    referrerPolicy="no-referrer"
                  />
                ) : undefined
              ) : undefined} */}
							{session ? (
								<Button
									startIcon={<Google />}
									variant="contained"
									size="large"
									color="primary"
									sx={{
										textTransform: "none",
										color: "#FFFFFF",
									}}
									onClick={() => {
										session
											? session.user
												? signOut()
												: signIn("google", {
														callbackUrl: process.env.PRODUCTION_URL || "",
													})
											: signIn("google", {
													callbackUrl: process.env.PRODUCTION_URL || "",
												});
									}}
								>
									{session ? (session.user ? "Sign Out" : "Sign in with Google") : "Sign In with Google"}
								</Button>
							) : undefined}
						</SocialLinkContainer>
					</LinkContainer>
				</Navbar>
			</StyledAppBar>
		</React.Fragment>
	);
};

/*

& Finally, let's export the component as default

*/

export default LayoutHeader;
