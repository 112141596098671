import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Alert, Button, TextField, Typography, styled } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";

const InputContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "1rem",
  width: "100%",

  // padding: "0.25rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
  width: "100%",
  fontWeight: "500",
  fontSize: "1.125rem",
  lineHeight: "1.25rem",
}));

const LatLngContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
}));

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
}));

const TimesOfDay = ({
  time,
  start_time,
  end_time,
}: {
  time: any;
  start_time: any;
  end_time: any;
}) => {
  const [starttime, setStarttime] = React.useState(
    dayjs(start_time ? start_time : "2100-01-1T00:00")
  );

  const [endtime, setEndtime] = React.useState(
    dayjs(end_time ? end_time : "2100-01-1T00:00")
  );

  const [focus, setFocus] = React.useState<any>("hours");

  const [focus2, setFocus2] = React.useState<any>("hours");

  const [startValue, setStartValue] = React.useState<Dayjs | null>(
    dayjs(start_time ? start_time : "2100-01-1T00:00")
  );

  const [endValue, setEndValue] = React.useState<Dayjs | null>(
    dayjs(end_time ? end_time : "2100-01-1T00:00")
  );
  const currentHours = new Date(starttime.toString()).getHours();
  const currentMinutes = new Date(starttime.toString()).getMinutes();

  const currentHoursend = new Date(endtime.toString()).getHours();
  const currentMinutesend = new Date(endtime.toString()).getMinutes();

  React.useEffect(() => {
    typeof time === "function" && time(starttime, endtime);
  }, [starttime, endtime, time]);
  return (
    <ContentContainer>
      <InputContainer>
        <LatLngContainer
          sx={{
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Heading>Start time</Heading>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeClock
              value={startValue}
              ampmInClock={true}
              onChange={(newValue) => {
                setStarttime(newValue.toString());
                setStartValue(newValue);
                setFocus("minutes");
              }}
              view={focus}
            />
          </LocalizationProvider>
          <InputContainer>
            <TextField
              id="outlined-basic"
              label="start time"
              variant="outlined"
              // disabled={true}
              value={
                ("0" + currentHours).slice(-2) +
                ":" +
                ("0" + currentMinutes).slice(-2)
              }
              onChange={(e) => {}}
              error={
                new Date(endtime.toString()).getHours() <
                new Date(starttime.toString()).getHours()
              }
              // helperText={
              //   new Date(endtime.toString()).getHours() <
              //   new Date(starttime.toString()).getHours()
              //     ? "End time should be greater than start time"
              //     : ""
              // }
            />
            {/* <ButtonContainer variant="contained" color="primary"> */}

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setStarttime(dayjs("2100-01-1T00:00"));
                setStartValue(dayjs("2100-01-1T00:00"));
                setFocus("hours");
              }}
              sx={{
                color: "#FFFFFF",
              }}
            >
              Reset
            </Button>
            {/* </ButtonContainer> */}
          </InputContainer>
        </LatLngContainer>
        <LatLngContainer
          sx={{
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Heading>End time</Heading>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeClock
              value={endValue}
              ampmInClock={true}
              onChange={(newValue) => {
                setEndtime(newValue.toString());
                setEndValue(newValue);
                setFocus2("minutes");
              }}
              view={focus2}
            />
          </LocalizationProvider>
          <InputContainer>
            <TextField
              id="outlined-basic"
              label="end time"
              variant="outlined"
              value={
                ("0" + currentHoursend).slice(-2) +
                ":" +
                ("0" + currentMinutesend).slice(-2)
              }
              error={
                new Date(endtime.toString()).getHours() <
                new Date(starttime.toString()).getHours()
              }
              // helperText={
              //   new Date(endtime.toString()).getHours() <
              //   new Date(starttime.toString()).getHours()
              //     ? "End time should be greater than start time"
              //     : ""
              // }
              onChange={(e) => {}}
            />
            {/* <ButtonContainer variant="contained" color="primary"> */}

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEndtime(dayjs("2100-01-1T00:00"));
                setEndValue(dayjs("2100-01-1T00:00"));
                setFocus2("hours");
              }}
              sx={{
                color: "#FFFFFF",
              }}
            >
              Reset
            </Button>
            {/* </ButtonContainer> */}
          </InputContainer>
        </LatLngContainer>
      </InputContainer>
      {new Date(endtime.toString()).getHours() <
      new Date(starttime.toString()).getHours() ? (
        <Alert
          variant="outlined"
          severity="error"
          sx={{
            width: "100%",
            // padding: "1rem",
            margin: "1rem 0rem 1rem 0rem",
          }}
        >
          The end date and time cannot be less than the start date and time.
          Please rectify this error in order to create or edit a manual boost
          override.
        </Alert>
      ) : null}
    </ContentContainer>
  );
};

export default TimesOfDay;
