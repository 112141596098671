import * as React from "react";

import dayjs from "dayjs";
import { Alert, Box, Switch } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";

const DaysOfWeek = ({ days, data }: { days: any; data: any }) => {
  const date = new Date(2100, 1, 1);
  const rows = [
    {
      id: 1,
      day: "Monday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 2,
      day: "Tuesday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 3,
      day: "Wednesday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 4,
      day: "Thursday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 5,
      day: "Friday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 6,
      day: "Saturday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 7,
      day: "Sunday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
  ];

  const [state, setState] = React.useState({
    rows: data ? data : rows,
  });

  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    typeof days === "function" && days(state.rows,error);
  }, [state, days,error]);

  const columns = [
    { field: "day", headerName: "Day", width: 150 },
    {
      field: "start_time",
      headerName: "Start Time",
      width: 250,

      renderCell: (cellValues: any) => {
        return (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={dayjs(cellValues.value.toString())}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                onChange={(newValue: any) => {
                  if (newValue) {
                    setState((prev: any) => {
                      const rows = prev.rows.map((row: any) => {
                        if (row.id === cellValues.id) {
                          return {
                            ...row,
                            start_time: newValue.$d,
                          };
                        }
                        return row;
                      });
                      return { ...prev, rows };
                    });
                  }
                }}
              />
            </LocalizationProvider>
          </>
        );
      },
    },
    {
      field: "end_time",
      headerName: "End Time",
      width: 250,
      renderCell: (params: any) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              defaultValue={dayjs("2100-01-01T00:00")}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              disablePast={
                new Date(params.row?.start_time).getHours() === 0 &&
                new Date(params.row?.start_time).getMinutes() === 0
              }
              onChange={(newValue: any) => {
                if (newValue) {
                  setState((prev: any) => {
                    const rows = prev.rows.map((row: any) => {
                      if (row.id === params.id) {
                        if (
                          new Date(row.start_time).getHours() >
                            new Date(newValue.$d).getHours() ||
                          (new Date(row.start_time).getHours() ===
                            new Date(newValue.$d).getHours() &&
                            new Date(row.start_time).getMinutes() >
                              new Date(newValue.$d).getMinutes())
                        ) {
                          setError(true);
                        } else {
                          setError(false);
                        }
                        return {
                          ...row,
                          end_time: newValue.$d,
                        };
                      }
                      return row;
                    });
                    return { ...prev, rows };
                  });
                }
              }}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      field: "in_use",
      headerName: "In Use",
      width: 150,
      renderCell: (cellValues: any) => {
        return (
          <Switch
            checked={
              data.find((item: any) => item.id === cellValues.id)?.in_use
            }
            onChange={(e) => {
              setState((prev: any) => {
                const rows = prev.rows.map((row: any) => {
                  if (row.id === cellValues.id) {
                    return {
                      ...row,
                      in_use: e.target.checked,
                    };
                  }
                  return row;
                });
                return { ...prev, rows };
              });
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
  ];

  return (
    <Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
      <DataGridPremium
        // disableRowSelectionOnClick={false}
        rows={data?.map((item: any, key: number) => {
          return {
            id: item ? item.id : key,
            day: item ? item.day : "",
            start_time: item ? item.start_time : "",
            end_time: item ? item.end_time : "",
            in_use: item ? item.in_use : "",
          };
        })}
        getRowId={(row) => row.id}
        columns={columns}
        sx={{
          backgroundColor: "#FFFFFF",
          "& > .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
        }}
        hideFooter
      />
      {error ? (
        <Alert
          variant="outlined"
          severity="error"
          sx={{
            width: "100%",
            // padding: "1rem",
            margin: "1rem 0rem 1rem 0rem",
          }}
        >
          The end date and time cannot be less than the start date and time.
          Please rectify this error in order to create or edit a manual boost
          override.
        </Alert>
      ) : null}
    </Box>
  );
};

export default DaysOfWeek;
