/* Common Imports */

import React from "react";

/* Component Imports */

import { styled } from "@mui/system";
import FooterLogo from "./FooterLogo";
import FooterSocialIcons from "./FooterSocialIcons";
import FooterCopyrightInfo from "./FooterCopyrightInfo";

const LogoAndSocial = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "1rem 2rem 1rem 2rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    margin: "1rem 0rem 1rem 0rem",
  },
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  borderTop: "1px solid rgba(0, 0, 0, 0.23)",
  backgroundColor: "#FFFFFF",
}));

const FooterHeader = () => {
  return (
    <React.Fragment>
      <LogoAndSocial>
        <FooterLogo />
        <FooterCopyrightInfo />
        <FooterSocialIcons />
      </LogoAndSocial>
    </React.Fragment>
  );
};

export default FooterHeader;
