import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useRouter } from "next/router";

const MainContainer = styled(DialogContentText)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "1rem",
}));

const Close = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(0.5),
  top: theme.spacing(0.5),
  color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  height: "4rem",
  width: "100%",
}));

const GrandAccessModal = ({ open, onClose }: { open: any; onClose: any }) => {
  const [phone, setPhone] = React.useState("");

  const router = React.useRef(useRouter()).current;

  const submit = async () => {
    await fetch(process.env.PRODUCTION_API_URL + "IAM-table/grant-user", {
      method: "POST",
      cache: "no-store",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({ phone }),
    });
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          change: "true",
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
    onClose();
  };
  return (
    <div>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onClose}
      >
        <DialogTitle>Add your phone number</DialogTitle>
        <Container>
          <Close aria-label="close" onClick={onClose}>
            <CloseIcon />
          </Close>
        </Container>
        <DialogContent>
          <MainContainer>
            <TextField
              id="outlined-basic"
              label="Phone Number"
              type="number"
              variant="outlined"
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
          </MainContainer>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              submit();
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GrandAccessModal;
