import * as React from "react";
import Dialog from "@mui/material/Dialog";

import {
  Box,
  DialogTitle,
  IconButton,
  Switch,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const Close = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(0.5),
  top: theme.spacing(0.5),
  color: theme.palette.grey[500],
}));

const DaysTableModal = ({
  open,
  onClose,
  tabledata,
}: {
  open: any;
  onClose: any;
  tabledata: any;
}) => {
  const date = new Date(2100, 1, 1);
  const [rows, setRows] = React.useState([
    {
      id: 1,
      day: "Monday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 2,
      day: "Tuesday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 3,
      day: "Wednesday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 4,
      day: "Thursday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 5,
      day: "Friday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 6,
      day: "Saturday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
    {
      id: 7,
      day: "Sunday",
      start_time: date,
      end_time: date,
      in_use: false,
    },
  ]);



  React.useEffect(() => {
    setRows([
      {
        id: 1,
        day: "Monday",
        start_time: tabledata?.monday?.activates_at,
        end_time: tabledata?.monday?.deactivates_at,
        in_use: tabledata?.monday?.in_use,
      },
      {
        id: 2,
        day: "Tuesday",
        start_time: tabledata?.tuesday?.activates_at,
        end_time: tabledata?.tuesday?.deactivates_at,
        in_use: tabledata?.tuesday?.in_use,
      },
      {
        id: 3,
        day: "Wednesday",
        start_time: tabledata?.wednesday?.activates_at,
        end_time: tabledata?.wednesday?.deactivates_at,
        in_use: tabledata?.wednesday?.in_use,
      },
      {
        id: 4,
        day: "Thursday",
        start_time: tabledata?.thursday?.activates_at,
        end_time: tabledata?.thursday?.deactivates_at,
        in_use: tabledata?.thursday?.in_use,
      },
      {
        id: 5,
        day: "Friday",
        start_time: tabledata?.friday?.activates_at,
        end_time: tabledata?.friday?.deactivates_at,
        in_use: tabledata?.friday?.in_use,
      },
      {
        id: 6,
        day: "Saturday",
        start_time: tabledata?.saturday?.activates_at,
        end_time: tabledata?.saturday?.deactivates_at,
        in_use: tabledata?.saturday?.in_use,
      },
      {
        id: 7,
        day: "Sunday",
        start_time: tabledata?.sunday?.activates_at,
        end_time: tabledata?.sunday?.deactivates_at,
        in_use: tabledata?.sunday?.in_use,
      },
    ]);
  }, [tabledata]);


  const columns = [
    { field: "day", headerName: "Day", width: 150 },
    {
      field: "start_time",
      headerName: "Start Time",
      width: 250,

      renderCell: (cellValues: any) => {
        return (
          <>
            {("0" + new Date(cellValues.value).getHours()).slice(-2) +
              ":" +
              ("0" + new Date(cellValues.value).getMinutes()).slice(-2)}
          </>
        );
      },
    },
    {
      field: "end_time",
      headerName: "End Time",
      width: 250,
      renderCell: (cellValues: any) => {
        return (
          <>
            {("0" + new Date(cellValues.value).getHours()).slice(-2) +
              ":" +
              ("0" + new Date(cellValues.value).getMinutes()).slice(-2)}
          </>
        );
      },
    },
    {
      field: "in_use",
      headerName: "In Use",
      width: 150,
      renderCell: (cellValues: any) => {
        const check = cellValues.value  ? cellValues.value : false;
        return (
          <Switch
            checked={check}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
  ];
  return (
    <>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Action Details
          <Close aria-label="close" onClick={onClose}>
            <CloseIcon />
          </Close>
        </DialogTitle>
        <Box sx={{ height: "auto", padding: " 1rem", width: "100%" }}>
          <DataGridPremium
            // disableRowSelectionOnClick={false}
            rows={rows?.map((item: any, key: number) => {
              return {
                id: item ? item.id : key,
                day: item ? item.day : "",
                start_time: item ? item.start_time : "",
                end_time: item ? item.end_time : "",
                in_use: item ? item.in_use : "",
              };
            })}
            getRowId={(row) => row.id}
            columns={columns}
            sx={{
              backgroundColor: "#FFFFFF",
              "& > .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
            }}
            hideFooter
          />
        </Box>
      </Dialog>
    </>
  );
};

export default DaysTableModal;
