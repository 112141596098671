/*
  *Common Imports

*/

import { styled } from "@mui/system";
import React from "react";
/*

*Redux Imports

*/

/*

* Material UI Imports

*/

import {
  useTheme,
  TextField,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
/*

* Google Maps Imports

*/
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

/*

* Icons Imports

*/

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const SearchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "0.688rem",
  margin: "0rem 0rem 0rem 0rem",
}));

const librariesToLoad: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places", "drawing", "geometry"];

const Search = ({
  locationData,
  change,
  latAndLng,
}: {
  locationData: any;
  change: any;
  latAndLng: Function;
}) => {
  /*

  & let's declare the states for the usePlacesAutocomplete hook

  */
  const {
    value,
    suggestions: { data },
    setValue,
    init,
  } = usePlacesAutocomplete({
    initOnMount: false,
    debounce: 500,
  });

  /*

  & let's declare the states for the useLoadScript hook

  */
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY || "",
    libraries: librariesToLoad as any,
  });

  const theme = useTheme();

  /*

  & let's define the function to handle the change in the location autocomplete

  */
  const onChangeLocationAutocomplete = async (
    event: React.SyntheticEvent<HTMLSelectElement>,
    value: string
  ) => {
    setValue(value as string);
    const { lat, lng } = getLatLng((await getGeocode({ address: value }))[0]);
    typeof latAndLng === "function" ? latAndLng(lat, lng) : null;
  };

  /*

  & let's use the useEffect hook to set the value of the location autocomplete to the default location and initialize the autocomplete

  */

  React.useEffect(() => {
    setValue(change);
    init();
  }, [init, change, setValue]);


  return (
    <SearchContainer>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={data.map((option) => {
          const def = option.terms.find((term) => term.value === value);
          return def ? def.value : option.structured_formatting.main_text;
        })}
        value={value}
        onChange={onChangeLocationAutocomplete}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnOutlinedIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Enter Location"
          />
        )}
      />
    </SearchContainer>
  );
};

export default Search;
