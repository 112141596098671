import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { DialogContent, DialogTitle, IconButton, styled } from "@mui/material";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));


const ErrorMessageModal = ({ open, onClose, errorMessage }: { open: any; onClose: any; errorMessage: any }) => {
	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						color: "primary.main",
					}}
				>
					Error Message
					<Close
						aria-label="close"
						onClick={onClose}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<DialogContent
					sx={{
						wordBreak: "break-word",
					}}
				>
					{errorMessage}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ErrorMessageModal;
