import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogActions, DialogTitle, IconButton, Typography, styled } from "@mui/material";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));


const ReminderForBusinessModal = ({ open, onClose, userId }: { open: any; onClose: any; userId: any }) => {
	const submitMessage = async () => {
		await fetch(process.env.PRODUCTION_API_URL + "buiness-profile-table/user-reminder-to-update-business-profile", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				userId: userId ? userId : "",
			}),
		});
	};

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography
						variant="h6"
						component="div"
					>
						Are you sure you want to send a reminder to update the business profile?
					</Typography>
					<Close
						aria-label="close"
						onClick={onClose}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							submitMessage();
							onClose();
						}}
					>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ReminderForBusinessModal;
