import * as React from "react";
import { Alert, Button, Typography, styled } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

const InputContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "1rem",
  width: "100%",

  // padding: "0.25rem",
}));

const LatLngContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
}));
const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  padding: "0.25rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
  width: "100%",
  fontWeight: "500",
  fontSize: "1.125rem",
  lineHeight: "1.25rem",
  color: "#F76C35",
}));

const OneOff = ({
  oneoff,
  start_time,
  end_time,
}: {
  oneoff: any;
  start_time: any;
  end_time: any;
}) => {
  const [dateAndTime, setDateAndTime] = React.useState<Dayjs | null>(
    dayjs(start_time ? start_time : new Date())
  );

  const [dateAndTime2, setDateAndTime2] = React.useState<Dayjs | null>(
    dayjs(end_time ? end_time : new Date())
  );

  React.useEffect(() => {
    typeof oneoff === "function" && oneoff(dateAndTime, dateAndTime2);
  }, [dateAndTime, dateAndTime2, oneoff]);
  return (
    <ContentContainer>
      <InputContainer>
        <LatLngContainer
          sx={{
            flexDirection: "column",
          }}
        >
          <Heading>Start time</Heading>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateTimePicker
              orientation="landscape"
              value={dateAndTime}
              disablePast
              onChange={(newValue) => {
                setDateAndTime(newValue);
              }}
              sx={{
                width: "100%",
                "& .MuiDialogActions-root": {
                  justifyContent: "center",
                  display: "none",
                },
              }}
            />
          </LocalizationProvider>
        </LatLngContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDateAndTime(null);
          }}
          sx={{
            color: "#FFFFFF",
          }}
        >
          Reset
        </Button>
      </InputContainer>
      <InputContainer>
        <LatLngContainer
          sx={{
            flexDirection: "column",
          }}
        >
          <Heading>End time</Heading>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateTimePicker
              orientation="landscape"
              value={dateAndTime2}
              disablePast
              onChange={(newValue) => {
                setDateAndTime2(newValue);
              }}
              sx={{
                width: "100%",
                "& .MuiDialogActions-root": {
                  justifyContent: "center",
                  display: "none",
                },
              }}
            />
          </LocalizationProvider>
        </LatLngContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDateAndTime2(null);
          }}
          sx={{
            color: "#FFFFFF",
          }}
        >
          Reset
        </Button>
      </InputContainer>
      {dateAndTime2 && dateAndTime && dateAndTime2 < dateAndTime ? (
        <Alert
          variant="outlined"
          severity="error"
          sx={{
            width: "100%",
            // padding: "1rem",
            margin: "1rem 0rem 1rem 0rem",
          }}
        >
          The end date and time cannot be less than the start date and time.
          Please rectify this error in order to create or edit a manual boost
          override.
        </Alert>
      ) : null}
    </ContentContainer>
  );
};

export default OneOff;
