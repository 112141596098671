/*

? First, let's import the essentials - React and Styled

*/

import React from "react";

/*

? Next, let's import all the UI components we need from MUI

*/

/*

? Next, let's import all the non-MUI UI components that we need

*/

import FooterHeader from "./footer-components/FooterHeader";

/*

& Next, let's describe the component

*/

const LayoutFooter = () => {
  return (
    <React.Fragment>
      <FooterHeader />
    </React.Fragment>
  );
};

/*

& Finally, let's export the component

*/

export default LayoutFooter;
