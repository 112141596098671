/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

/* Component Imports */

import { Paper, Typography } from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import dynamic from "next/dynamic";
import usePlacesAutocomplete from "use-places-autocomplete";

/* Icon Imports */

import Search from "./search";
import SliderRadius from "./SliderRadius";

/*
&.Location Picker library imports

*/

const LocationPicker = dynamic<{
	containerElement: React.ReactNode;
	mapElement: React.ReactNode;
	onChange: Function;
	defaultPosition: object;
	zoom?: number;
	radius: number;
	circleOptions?: object;
	//@ts-ignore
}>(() => import("@beegru/react-location-picker"), {
	ssr: false,
});

/*

& Next, let's describe an interface to determine the shape of the body that we will send to the GecodeApi endpoint.

*/

interface GMRGCPlusCode {
	compound_code: string;
	global_code: string;
}
interface GMRGCResultsAddressComponent {
	long_name: string;
	short_name: string;
	types: Array<string>;
}
interface GMRGCResultsGeometryLocation {
	lat: number;
	lng: number;
}
interface GMRGCResultsGeometryBoundsAndGeometryViewport {
	northeast: GMRGCResultsGeometryLocation;
	southwest: GMRGCResultsGeometryLocation;
}
interface GMRGCResultsGeometry {
	bounds: GMRGCResultsGeometryBoundsAndGeometryViewport;
	location: GMRGCResultsGeometryLocation;
	location_type: string;
}
interface GMRGCResults {
	address_components: Array<GMRGCResultsAddressComponent>;
	formatted_address: string;
	geometry: GMRGCResultsGeometry;
	viewport: GMRGCResultsGeometryBoundsAndGeometryViewport;
	place_id: string;
	types: Array<string>;
}
interface GoogleMapsReverseGeocodingApiResponse {
	plus_code: GMRGCPlusCode;
	results: Array<GMRGCResults>;
	status: string;
}

/* Styled Components */

const MapboxContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	// width: '44.875rem',
	// height: "30rem",
	borderRadius: "8px",
	padding: "0.5rem 0.5rem 0.5rem 0.5rem",
	width: "100%",
	boxShadow: theme.palette.mode == "dark" ? "" : "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
	background: theme.palette.background.paper,
}));

const Heading = styled(Typography)(({ theme }) => ({
	width: "100%",
	fontWeight: "500",
	fontSize: "1.125rem",
	lineHeight: "1.25rem",
}));

const MapContainer = styled("div")(({ theme }) => ({
	width: "100%",
	overflow: "hidden",
}));

const Map = styled("div")(({ theme }) => ({
	height: "20rem",
	overflow: "hidden",
}));

const EditContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.5rem",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	padding: "0rem 0rem 0.25rem 0rem",
	width: "100%",
}));

const SearchContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.688rem",
}));

const InputContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.25rem",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontWeight: "400",
	fontSize: "0.75rem",
	lineHeight: "1.125rem",
}));

const LatLngContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const calculateZoom: (radius: number) => number = (radius: number) => {
	return Math.round(14 - Math.log(radius) / Math.LN2) + 0.5;
};

const librariesToLoad: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = [
	"places",
	"drawing",
	"geometry",
];

const Mapbox = ({
	locationData,
	location,
	locationRadius,
}: {
	locationData: any;
	location: any;
	locationRadius: any;
}) => {
	const [change, setChange] = React.useState<{ lat: number; lng: number }>({
		lat: locationData?.coordinates[1] || 12.9716,
		lng: locationData?.coordinates[0] || 77.5946,
	});

	/*

  & Let's declare the router. Here we are using the useRouter hook from nextjs.

  & We are using the router to get the query params from the url. Memoize the router object to prevent unnecessary re-renders.

  */

	/* State to check if map is loaded */

	const [address, setAddress] = React.useState<string>("");
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY || "",
		libraries: librariesToLoad as any,
	});

	const {
		value,
		suggestions: { data },
		setValue,
	} = usePlacesAutocomplete({
		initOnMount: false,
		debounce: 500,
	});

	/*

  & let's get the location and pincode from the map and set it to the state

  */

	const handleLocationChange = async ({
		position,
		address,
	}: {
		position: { lat: number; lng: number };
		address: string;
	}) => {
		// setState({ position, address });
		setChange(position);
	};

	const [radius, setRadius] = React.useState(locationRadius ? locationRadius : 10);

	const handleChange = (event: any) => {
		setRadius(event.target.value);
	};

	/*

  & let's use reverseGeocodeApi to get the locality and pincode from the coordinates and set it to the state

  */

	React.useEffect(() => {
		const executor: Function = async () => {
			const googleMapsReverseGeocodingApiResponse: GoogleMapsReverseGeocodingApiResponse = await (
				await fetch(
					"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
						change.lat +
						"," +
						change.lng +
						"&key=" +
						(process.env.GOOGLE_MAPS_API_KEY || "") +
						"&callback=initMap",

					{ cache: "no-store" },
				)
			).json();

			const userCurrentAddresses: Array<string> | undefined = googleMapsReverseGeocodingApiResponse?.results.map(
				(result) => {
					return result.address_components?.find(
						(address_component: GMRGCResultsAddressComponent) =>
							address_component.types.includes("sublocality_level_1") || address_component.types.includes("locality"),
					)?.long_name as string;
				},
			);

			let userCurrentAddress: string | undefined;

			let resultPos: number = 0;

			for (let i = 0; i < userCurrentAddresses.length; i++) {
				if (userCurrentAddresses[i] !== "Bengaluru") {
					if (userCurrentAddresses[i] !== undefined) {
						userCurrentAddress = userCurrentAddresses[i];
						resultPos = i;
						break;
					} else {
						i++;
					}
				}
			}

			setAddress(userCurrentAddress || "Bengaluru");

			const userCurrentAddressPin: string | undefined = googleMapsReverseGeocodingApiResponse?.results[
				resultPos
			]?.address_components?.find(
				(address_component: GMRGCResultsAddressComponent) =>
					address_component.types.includes("postal_code") || address_component.types.includes("pincode"),
			)?.long_name;

			setValue(userCurrentAddressPin || "");
		};
		executor();
	}, [change, setValue]);

	React.useEffect(() => {
		typeof location === "function" && location(change, address, radius);
	}, [location, change, address, radius]);

	return (
		<MapboxContainer id="map">
			<TextContainer>
				<EditContainer>
					<SearchContainer>
						<Search
							locationData={change}
							change={address}
							latAndLng={(lat: number, lng: number) => {
								setChange({ lat, lng });
							}}
						/>
					</SearchContainer>
					{isLoaded && (
						<LocationPicker
							containerElement={<MapContainer />}
							mapElement={<Map />}
							defaultPosition={{
								lat: change.lat,
								lng: change.lng,
							}}
							radius={radius * 1000}
							zoom={calculateZoom(radius)}
							// zoom={(radius * 0.75) / 10}
							onChange={handleLocationChange}
						/>
					)}
				</EditContainer>
				<InputContainer>
					<LatLngContainer sx={{ flexDirection: "column", gap: "0rem" }}>
						<Heading>Latitude</Heading>
						<Text>{change.lat}</Text>
					</LatLngContainer>
					<LatLngContainer sx={{ flexDirection: "column", gap: "0rem" }}>
						<Heading>Longitude</Heading>
						<Text>{change.lng}</Text>
					</LatLngContainer>
					<SliderRadius
						radius={radius}
						handleChange={handleChange}
					/>
				</InputContainer>
			</TextContainer>
		</MapboxContainer>
	);
};
export default Mapbox;
