/* Common Imports */

import React from "react";

/* Component Imports */

import { styled } from "@mui/system";
import { Typography } from "@mui/material";

const CopyrightInfo = styled("div")(({ theme }) => ({}));

const FooterCopyrightDesktop = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.5rem",
}));

const FooterCopyrightMobile = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  padding: "0rem 0rem 1rem 0rem",
}));

const FooterCopyrightInfo = () => {
  const currentYear = new Date().getFullYear();
  return (
    <React.Fragment>
      <CopyrightInfo>
        <FooterCopyrightDesktop variant="body2">
          {"© " +
            currentYear +
            " Beegru Real Estate Private Limited. All Rights Reserved."}
        </FooterCopyrightDesktop>
        <FooterCopyrightMobile variant="body2">
          {"© " +
            currentYear +
            " Beegru Real Estate Private Limited. All Rights Reserved."}
        </FooterCopyrightMobile>
      </CopyrightInfo>
    </React.Fragment>
  );
};

export default FooterCopyrightInfo;
