import * as React from "react";
import Dialog from "@mui/material/Dialog";

import {
 
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const Close = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(0.5),
  top: theme.spacing(0.5),
  color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
  padding: "1.5rem",
}));



const ActionModal = ({
  open,
  onClose,
  bugdata,
}: {
  open: any;
  onClose: any;
  bugdata: any;
}) => {


 
  return (
    <div>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
         Action Details
          <Close aria-label="close" onClick={onClose}>
            <CloseIcon />
          </Close>
        </DialogTitle>
        <Container>
         {bugdata}
        </Container>
      </Dialog>
    </div>
  );
};

export default ActionModal;
