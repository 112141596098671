import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { DialogContent, DialogTitle, IconButton, Link, styled } from "@mui/material";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const PublishedUnpublishedLinksModal = ({
	open,
	onClose,
	publishedAndUnpublishedArray,
	isProperties,
}: {
	open: any;
	onClose: any;
	publishedAndUnpublishedArray: any;
	isProperties: boolean;
}) => {
	const properLink = isProperties ? "properties" : "services";
	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="xs"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						color: "primary.secondary",
					}}
				>
					Published and Unpublished Links
					<Close
						aria-label="close"
						onClick={onClose}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<DialogContent>
					{publishedAndUnpublishedArray.map((item: any, index: number) => (
						<div key={index}>
							<Link
								href={"https://beegru.com/" + properLink + "/" + item}
								target="_blank"
								variant="h6"
								sx={{
									color: "primary",
									fontWeight: "400",
									fontSize: "1rem",
									lineHeight: "2rem",
									textDecoration: "none",
								}}
							>
								{item}
							</Link>
						</div>
					))}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default PublishedUnpublishedLinksModal;
